import React, { useEffect, useRef } from "react";
import { PropsWithChildren } from "react";
import { ReturnPage } from "src/types";


export interface PageWrapperProps {
    page: ReturnPage;
    onVisible: (page: ReturnPage) => void;
    pageLoaded: boolean;
}

export const PageWrapper: React.FC<PropsWithChildren<PageWrapperProps>> = ({
    children,
    onVisible,
    page,
    pageLoaded,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && pageLoaded) {
                            onVisible(page);
                        }
                    });
                },
                {
                    root: null,
                    threshold: 0.1, // adjusted due to browser zoom settings.
                    rootMargin: "0px",
                },
            );
    
            if (ref.current) {
                observer.observe(ref.current);
            }
    
            return () => {
                if (ref.current) {
                    observer.disconnect();
                }
            };
        }, 100)
    }, []);

    return <div ref={ref}>{children}</div>;
};
