import React from "react";
import { LabelFieldTemplate, ReturnPage } from "src/types";
import "./LabelField.scss";
import { useDebugModeDisplayHidden } from "../../../hooks/debugModeDisplayHidden";

const LabelField: React.FC<{ scale: number; field: LabelFieldTemplate, returnPage: ReturnPage, id:string }> = ({
    scale,
    field,
    id,
}) => {
    const { style, textValue, isVisible } = field;
    const { position, size } = field.screenBox!;
    const debugModeDisplayHidden = useDebugModeDisplayHidden();

    const labelStyle: React.CSSProperties = {
        left: `${position?.left * scale}px`,
        top: `${position?.top * scale}px`,
        height: `${size?.height * scale}px`,
        width: `${size?.width * scale}px`,
        textAlign: `${style?.alignment}`.toLowerCase() as "left" | "right" | "center",
        fontSize: `${style?.fontSize! * scale}px`,
        fontWeight: style?.bold ? "bold" : "normal",
        fontStyle: style?.italic ? "italic" : "normal",
        display: debugModeDisplayHidden? "block" : isVisible ? "block" : "none",
    };

    return (
        <div
            id={id}
            data-testid={id}
            className="label-field-container"
            style={labelStyle}
            onClick={() => console.log("Clicked on label field")}
        >
            {textValue}
        </div>
    );
};

export default LabelField;
