import FeatureFlags from "src/utils/FeatureFlags";
import { handlePrintExport } from "./PrintExport.util";
import { Save as SaveIcon, Print as PrintIcon } from "@mui/icons-material";

export const getToolbarIcons = (
    apiRef,
    documentKey,
    handleSnackbar,
    onSaveClick,
    pendingChanges,
) => {
    // Toolbar icon data here
    const iconData = [
        {
            position: 1,
            icon: SaveIcon,
            title: "Save",
            onClick: onSaveClick,
            isEnabled: true,
            isActive: pendingChanges,
            isValid: true,
        },
    ];

    if (FeatureFlags.showOnlyInLocalDevAndQa()) {
        iconData.push({
            position: iconData.length + 1,
            icon: PrintIcon,
            title: "Print",
            onClick: async () =>
                handlePrintExport(apiRef, documentKey, handleSnackbar),
            isEnabled: true,
            isActive: true,
            isValid: true,
        });
    }

    return iconData;
};
