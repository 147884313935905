import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { FixMeLater, QueryParams } from "src/types";
import "./SignatureField.scss";
import ESignatureModal from "src/components/ESignatureModal/ESignatureModal";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import { Modal } from "src/uikit";
import { ReturnDocumentService } from "src/services";
import { HttpError } from "src/utils/HttpError";
import { useAppSelector } from "src/hooks";
import { useDebugModeDisplayHidden } from "../../../hooks/debugModeDisplayHidden";

const DEFAULT_HEIGHT = 30;
const DEFAULT_WIDTH = 292;

export interface SignatureFieldProps {
    scale: number;
    field: FixMeLater;
    returnInput: QueryParams,
    isLocked?: boolean;
}

const SignatureField: FC<SignatureFieldProps> = ({
    scale,
    field,
    returnInput,
    isLocked,
}) => {
    const returnDocumentService = ReturnDocumentService.getInstance();
    const debugModeDisplayHidden = useDebugModeDisplayHidden();

    const { isVisible, fieldType, id } = field;
    const { position } = field.screenBox || {};

    const signatureStyle: CSSProperties = {
        left: `${position?.left * scale}px`,
        top: `${position?.top * scale}px`,
        height: `${DEFAULT_HEIGHT * scale}px`,
        width: `${DEFAULT_WIDTH * scale}px`,
        display: debugModeDisplayHidden? "block" : isVisible ? "block" : "none",
    };

    const [signatureModalOpen, setSignatureModalOpen] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | string[]>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");
    const [snackbarDuration, setSnackbarDuration] = useState<number>(3000);

    const imgRef = useRef<HTMLImageElement>(null);

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const toogleFetchSignature: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.toogleFetchSignature,
    );

    useEffect(() => {
        const fetchSignature = async () => {
            try {
                const response = await returnDocumentService.getSignature(returnInput);              
                if (imgRef.current && response?.size) {
                    imgRef.current.src = URL.createObjectURL(response);
                }
            } catch (error: FixMeLater) {
                if (error instanceof HttpError) {
                    handleSnackbar(JSON.parse(error?.body)?.message, "error");
                } else {
                    handleSnackbar("Error fetching signature", "error");
                }
            }
        }
        fetchSignature();
    }, [toogleFetchSignature]);

    const openSignatureModal = () => {
        if (isLocked) return;
        setSignatureModalOpen(true);
    }

    const handleSnackbar = (message: string | string[], severity: string, autoHideDuration: number = 3000) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
        setSnackbarDuration(autoHideDuration);
    };

    return (
        <>
            <div
            className={`signature-field-container ${ isLocked ? "signature-field-container--locked" : ""}`}
                style={signatureStyle}
                data-testid={id + fieldType}
                onClick={openSignatureModal}
            >
                <img className="signature-field-img" ref={imgRef}></img>
            </div>

            <Modal
                open={signatureModalOpen}
                onClose={() => setSignatureModalOpen(false)}
                title={"Sign the document"}
            >
                <ESignatureModal
                    onClose={() => setSignatureModalOpen(false)}
                    handleSnackbar={handleSnackbar}
                    returnInput={returnInput}
                />
            </Modal>

            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                autoHideDuration={snackbarDuration}
            />
        </>
    );
};

export default SignatureField;
