import React, { FC, useRef, useState } from "react";
import { ReturnDocumentService } from "src/services";
import { FixMeLater, QueryParams } from "src/types";
import "./ESignatureModal.scss";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { Tab, Typography } from "@mui/material";
import { HttpError } from "src/utils/HttpError";
import { StyledContainedButton, StyledTabs, StyledTextButton } from "./ESignatureModal.styled";
import ReactSignatureCanvas from "react-signature-canvas";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import UploadTab from "./UploadTab/UploadTab";
import TypingTab from "./TypingTab/TypingTab";

const RESERVED_SPACE = 200;
const FONT_SIZE = "100px";
const X_CANVAS_POSITION = 10;
const Y_CANVAS_POSITION = 100;

interface ESignatureModalProps {
    onClose: () => void;
    handleSnackbar: (message: string | string[], severity: string, autoHideDuration?: number) => void;
    returnInput: QueryParams,
}

const ESignatureModal: FC<ESignatureModalProps> = ({onClose, handleSnackbar, returnInput}) => {
    const returnDocumentService = ReturnDocumentService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [value, setValue] = useState(0);
    const [fileSignature, setFileSignature] = useState<File | null>(null);
    const [textSignature, setTextSignature] = useState<string>("");
    const [signatureFont, setSignatureFont] = useState<string>("Arabella");

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value?.selectedState?.abbrev,
    );
    const municipalQuarter: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedQuarter,
    );

    const textCanvasRef = useRef<ReactSignatureCanvas>(null);

    const dispatch = useAppDispatch();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const uploadSignature = async (file: File | null) => {
        if (!file) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", file, file.name)
        const attachmentMetadata = new Blob(
            [
                JSON.stringify(
                    returnInput
                ),
            ],
            { type: "application/json" },
        );

        formData.append("returnInput", attachmentMetadata);

        try {
            await returnDocumentService.signReturnDocument(formData);
            dispatch(GlobalStateActions[product?.productName].setToogleFetchSignature());
        } catch (error: FixMeLater) {
            if (error instanceof HttpError) {
                handleSnackbar(JSON.parse(error?.body)?.message, "error");
            } else {
                handleSnackbar("Error uploading signature", "error");
            }
        } finally {
            onClose();
            setIsLoading(false);
        }
    };

    const fillTextCanvas = () => {
        setIsLoading(true);
        
        const canvas = textCanvasRef.current;
        const context = canvas?.getCanvas()?.getContext("2d");

        if (context) {
            // setting font and size for correct measuring text
            context.font = `${FONT_SIZE} ${signatureFont}`;
            context.canvas.width = context.measureText(textSignature?.trim()).width + RESERVED_SPACE;
            context.canvas.height = RESERVED_SPACE;
            // need to set font and size again after resizing
            context.font = `${FONT_SIZE} ${signatureFont}`;
            context.fillText(textSignature?.trim(), X_CANVAS_POSITION, Y_CANVAS_POSITION);
        } else {
            handleSnackbar("Error getting signature context. Please try again.", "error");
            setIsLoading(false);
        }

        canvas?.getTrimmedCanvas()?.toBlob((blob: Blob | null) => {
            if (blob) {
                const file = new File([blob], "Signature")
                uploadSignature(file);
            } else {
                handleSnackbar("Error creating signature", "error");
                setIsLoading(false);
            }
        }, "image/png");
    };

    return (
        <div className="e-signature-modal-container">
            <StyledTabs value={value} onChange={handleChange} >
                <Tab label="Image" disabled={isLoading}/>
                <Tab label="Type" disabled={isLoading}/>
            </StyledTabs>
            <UploadTab 
                value={value} 
                index={0} 
                isLoading={isLoading}
                fileSignature={fileSignature}
                setFileSignature={setFileSignature}
                handleSnackbar={handleSnackbar}
            />   
            <TypingTab
                value={value} 
                index={1} 
                isLoading={isLoading}
                textSignature={textSignature}
                setTextSignature={setTextSignature}
                signatureFont={signatureFont} 
                setSignatureFont={setSignatureFont}
            />
            <ReactSignatureCanvas
                ref={textCanvasRef}
                canvasProps={{ style: { display: "none" }}}
            />
            <Typography>Signature will appear on all other returns for {municipalState}, {municipalQuarter}, {product?.taxYear.toString()}</Typography>
            <div className="e-signature-modal-actions">
                <StyledContainedButton
                    loading={isLoading}
                    variant="contained"
                    disabled={value === 1 ? !textSignature?.trim()?.length : !fileSignature}
                    onClick={() => value === 1 ? fillTextCanvas() : uploadSignature(fileSignature)}
                >
                    Sign
                </StyledContainedButton>
                <StyledTextButton
                    disabled={isLoading}
                    onClick={onClose}
                >
                    Cancel
                </StyledTextButton>
            </div>
        </div>
    );
};

export default ESignatureModal;
