import styled from "styled-components";
import { DataGridPremium, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import { LoadingButton } from "@mui/lab";
import colors from "src/styles/colors.scss";

interface StyledDataGridProps {
    $rowId?: string;
}

interface StyledToolbarQuickFilterProps {
    $isDisabled?: boolean;
}

export const StyledDataGrid = styled(DataGridPremium)<StyledDataGridProps>`
    border: none;
    outline: none;

    .MuiDataGrid-scrollbar {
        scrollbar-width: thin;
        scrollbar-color: ${colors.allocatorContainerBackgroundColor}
            ${colors.grey};
        --DataGrid-scrollbarSize: 10px;

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: ${colors.grey};
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${colors.allocatorContainerBackgroundColor};
            border: 1px solid ${colors.grey};
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: ${colors.white};
        }
    }

    .MuiDataGrid-virtualScroller {
        border-radius: 0 !important;
    }

    .MuiDataGrid-filler div {
        border: none;
    }

    .MuiDataGrid-filler--pinnedLeft {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 3px 1px;
        clip-path: inset(0px -5px 0px 0px);
    }

    .MuiDataGrid-container--top [role=row] {
        background-color: ${colors.allocatorGridHeaderBackgroundColor};
    }

    .MuiDataGrid-columnHeaderTitle {
        font-weight: bold;
    }

    .MuiDataGrid-columnHeader--pinnedLeft {
        background-color: ${colors.white} !important;
    }

    .MuiDataGrid-columnHeader:last-child {
        .MuiDataGrid-columnSeparator {
            margin-right: 10px;
        }
    }
    
    .MuiDataGrid-columnHeader:focus {
        outline: none;
    }

    .MuiDataGrid-footerContainer {
        border: none;
    }

    .MuiDataGrid-cell {
        border: none;
        display: flex;
        align-items: center;

        &.MuiDataGrid-cell--editing {
            box-shadow: none;
            border: 1px solid ${colors.primaryColor};
            background-color: ${colors.allocatorComponentBackgroundLightColor};

            &:focus-within {
                outline: none;
            }
        }

        &:focus {
            outline: none;
        }

        &:focus-within {
            outline: none;
        }
    }

    .MuiDataGrid-cell--pinnedLeft {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 3px 1px;
        clip-path: inset(0px -5px 0px 0px);
    }

    .MuiDataGrid-editInputCell {
        color: ${colors.allocatorGridTextEditableColor};
    }

    .MuiDataGrid-row.Mui-even {
        background-color: ${colors.allocatorGridRowBackgroundColor};

        .MuiDataGrid-cell--pinnedLeft {
            background-color: ${colors.allocatorGridRowBackgroundColor};
        }
    }

    .MuiDataGrid-row[data-id="${(props) => props.$rowId}"] {
        background-color: ${colors.allocatorComponentBackgroundLightColor};
        outline: 1px solid #1976d2;
        outline-offset: -1px;

        .MuiDataGrid-cell--pinnedLeft {
            background-color: ${colors.allocatorComponentBackgroundLightColor};
            border: 1px solid #1976d2;
            border-right: none;
        }
    }
    
    .MuiDataGrid-row:hover {
        background-color: ${colors.allocatorComponentBackgroundLightColor};

        .MuiDataGrid-cell--pinnedLeft {
            background-color: ${colors.allocatorComponentBackgroundLightColor} !important;
        }

        .MuiDataGrid-cell--pinnedLeft.total {
            background-color: ${colors.allocatorComponentBackgroundColor} !important;
        }
    }

    .MuiDataGrid-scrollbarFiller--header {
        background-color: ${colors.allocatorGridHeaderBackgroundColor};
    }

    .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop {
        border: none;
    }

    .MuiDataGrid-scrollbarFiller.total {
        background-color: ${colors.allocatorGridHeaderBackgroundColor};
    }

    .MuiDataGrid-rowCount {
        display: none;
    }

    .MuiDataGrid-iconButtonContainer {
        display: none;
    }
`;

export const StyledContainedButton = styled(LoadingButton)`
    color: ${colors.white};
    background-color: ${colors.primaryColor};

    &: hover {
        box-shadow: none;
        background-color: ${colors.allocatorSelectedContainerBorderColor};
    }
`;

export const StyledTextButton = styled(LoadingButton)`
    color: ${colors.primaryColor};
    background-color: ${colors.white};

    &: hover {
        background-color: ${colors.allocatorComponentBackgroundLightColor};
    }
`;

export const StyledOutlinedButton = styled(StyledTextButton)`
    border: 1px solid ${colors.primaryColor};

    &: hover {
        border: 1px solid ${colors.primaryColor};
    }

    .Mui-disabled {
        border: 1px solid ${colors.grey};
    }
`;

export const StyledToolbarQuickFilter = styled(GridToolbarQuickFilter)<StyledToolbarQuickFilterProps>`
    padding: 0;

    .MuiInput-root {
        min-height: 36px;
        color: ${(props) => props.$isDisabled ? colors.grey: "inherit" };
    }

    .MuiSvgIcon-root {
        font-size: 22px;
        color: ${(props) => props.$isDisabled ? colors.grey: colors.allocatorTextGreyColor };
    }

    .MuiInput-underline:before {
        border-bottom: 1px solid ${(props) => props.$isDisabled ? colors.grey: colors.primaryColor };
    }

    .MuiInput-underline:after {
        border-bottom: 1px solid ${(props) => props.$isDisabled ? colors.grey: colors.primaryColor };
    }
`;
