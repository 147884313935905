import { Middleware } from "@reduxjs/toolkit";
import { setProduct } from "../slices/ProductSlice";
import { setDropDownState } from "../slices/StatesSlice";
import { Product } from "src/types";

export const productMiddleware: Middleware = (store) => (next) => (action) => {
    if (setProduct.match(action)) {
        const product = action.payload as Product | undefined;
        const productName = product?.productName;

        // Dropdown state should be set to false for all other products except Premium and FormsPlus
        if (productName !== "Premium" && productName !== "FormsPlus") {
            store.dispatch(setDropDownState(false));
        }
    }

    // Continue processing the action
    return next(action);
};
