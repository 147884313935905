import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { getFirstPathSegment, getModuleId } from "src/services/Utility";

import {
    Company,
    FolderNode,
    Product,
    QueryParams,
    ReturnDocumentId,
    ReturnNode,
} from "src/types";

import "./PdfViewer.scss";

import {
    AttachEmail as AttachEmailIcon,
    AttachFile as AttachFileIcon,
    ContentPasteGo as ContentPasteGoIcon,
    Info as InfoIcon,
    Save as SaveIcon,
    SaveAlt as SaveAltIcon,
    EditNote,
    Password as PasswordIcon,
} from "@mui/icons-material";
import ExplicitIcon from "@mui/icons-material/Explicit";
import { ReturnPDFService } from "src/services";
import { CreateReturnPDFPayload } from "src/types";
import { downloadFile, getQueryParams } from "src/utils";
import { HttpError } from "src/utils/HttpError";
import PaymentRequestIcon from "../../assets/images/toolbar-icons/payment-request.png";
import PreviousReturnIcon from "../../assets/images/toolbar-icons/previous-return.png";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

const returnPDFService = ReturnPDFService.getInstance();

export async function onInstructionsClick(
    product: Product,
    folderNode: FolderNode,
    company: Company,
    returnNode: ReturnNode,
    handleSnackbar: (message: string, severity: string) => void,
    municipalState: string,
    dropDownState: boolean,
) {
    const params: QueryParams = getQueryParams({
        productId: product.productId,
        taxYearId: product.taxYear,
        folderId: folderNode.id,
        moduleId: getModuleId(
            product,
            company ? (dropDownState ? folderNode : company) : {},
            municipalState,
        ),
        returnId: returnNode.id,
        retalFolderId: returnNode.retalFolderId,
        companyId: dropDownState ? folderNode?.id?.toString() : company.id,
    });

    try {
        const pdf = await returnPDFService.getReturnPDFInstruccions(params);
        const blob = new Blob([pdf], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);
        handleSnackbar("Instructions loaded successfully", "success");
        window.open(pdfUrl, "_blank");
    } catch (e) {
        if (e instanceof Error || e instanceof HttpError) {
            if (e.message === "Not Found") {
                handleSnackbar("Error instructions not found", "error");
            } else {
                handleSnackbar("Error downloading instructions", "error");
            }
        }
    }
}

// Toolbar button click handlers
export async function onPrintClick(
    company,
    product,
    folderNode,
    returnNode,
    handleSnackbar,
    municipalState,
    dropDownState,
) {
    const returnDocId: CreateReturnPDFPayload = {
        returnDocId: {
            companyId: dropDownState ? folderNode?.id?.toString() : company.id,
            productId: product.productId,
            taxYearId: product.taxYear,
            folderId: folderNode.id,
            moduleId: Number(
                getModuleId(
                    product,
                    company ? (dropDownState ? folderNode : company) : {},
                    municipalState,
                ),
            ),
            returnId: returnNode.id,
            retalFolderId: returnNode.retalFolderId,
        },
        pages: [], // Pages are required, but not used on the backend side; check stratus-api ReturnPdfController -> createReturnPdf function
    };

    try {
        const returnDownloadable =
            await returnPDFService.generateDownloadableReturnPDF(returnDocId);
        const { blob, contentDispositionHeader } = returnDownloadable;
        downloadFile(blob, contentDispositionHeader?.split("filename=")[1]);
        handleSnackbar("File downloaded successfully", "success");
    } catch (error) {
        handleSnackbar("Error downloading file", "error");
        console.error(error);
    }
}

export function onEfileEmailClick(setEfileEmailModalOpen) {
    setEfileEmailModalOpen(true);
}

export function onEfileClick(setEfileModalOpen) {
    setEfileModalOpen(true);
}

export async function onButtonClick(setShowPaymentRequest, showPaymentRequest) {
    setShowPaymentRequest(!showPaymentRequest);
}
// Toolbar icon data
export const getIconData = (
    hasPriorYearReturn,
    hasInstructionsPdf,
    isManualSave,
    hasPaymentRequest,
    hasOptinsExport,
    validationResponse,
    returnNode,
    pushDocument,
    setEfileEmailModalOpen,
    setEfileModalOpen,
    returnDocument,
    priorYearReturn,
    product,
    folderNode,
    company,
    handleSnackbar,
    setShowPaymentRequest,
    showPaymentRequest,
    openAttachments,
    optinsExport,
    municipalState,
    dispatch,
    returnNotes,
    onOpenPriorYearReturn,
    onWebLoginClick,
    dropDownState,
) => [
    {
        position: 1,
        icon: PreviousReturnIcon,
        title: "Open Prior Year's Return",
        onClick: () => onOpenPriorYearReturn(priorYearReturn),
        isEnabled: hasPriorYearReturn,
        isActive: true,
        isValid: true,
    },
    {
        position: 2,
        icon: InfoIcon,
        title: "Instructions",
        onClick: () =>
            onInstructionsClick(
                product,
                folderNode,
                company,
                returnNode,
                handleSnackbar,
                municipalState,
                dropDownState,
            ),
        isEnabled: true,
        isActive: hasInstructionsPdf,
        isValid: true,
    },
    {
        position: 3,
        icon: SaveAltIcon,
        title: "Download",
        onClick: () =>
            onPrintClick(
                company,
                product,
                folderNode,
                returnNode,
                handleSnackbar,
                municipalState,
                dropDownState,
            ),
        isActive: true,
        isValid: true,
        isEnabled: true,
    },
    {
        position: 4,
        icon: SaveIcon,
        title: "Save",
        onClick: () => pushDocument({ ...returnDocument }),
        isActive: isManualSave,
        isValid: true,
        isEnabled: true,
    },
    {
        position: 5,
        icon: PaymentRequestIcon,
        title: "Payment Request",
        onClick: () => onButtonClick(setShowPaymentRequest, showPaymentRequest),
        isEnabled: true,
        isActive: hasPaymentRequest,
        isValid: true,
    },
    {
        position: 6,
        icon: AttachFileIcon,
        title: "Attachments",
        onClick: openAttachments,
        isActive: true,
        isValid: true,
        isEnabled: true,
    },
    {
        position: 7,
        icon: ContentPasteGoIcon,
        title: returnNode.isReady
            ? "Optins Export"
            : "Optins Export ( Not Available yet )",
        onClick: optinsExport,
        isEnabled: returnNode.isReady,
        isActive: hasOptinsExport,
        isValid: validationResponse?.successful,
    },
    {
        position: 8,
        icon: AttachEmailIcon,
        title: returnNode.isReady
            ? "Efile Via Email"
            : "Efile Via Email ( Not Available yet )",
        onClick: () => onEfileEmailClick(setEfileEmailModalOpen),
        isEnabled: returnNode.isReady && validationResponse,
        isActive: returnNode?.efileEmail,
        isValid: validationResponse?.successful,
    },
    {
        position: 9,
        icon: ExplicitIcon,
        title: returnNode.isReady ? "Efile" : "Efile ( Not Available yet )",
        onClick: () => onEfileClick(setEfileModalOpen),
        isEnabled: returnNode.isReady && validationResponse,
        isActive: returnNode?.efile,
        isValid: validationResponse?.successful,
    },
    {
        position: 10,
        icon: EditNote,
        title: "Notes",
        onClick: () => {
            dispatch(
                GlobalStateActions?.[product?.productName].setReturnNotes({
                    ...returnNotes,
                    showNotes: !returnNotes.showNotes,
                }),
            );
        },
        isEnabled: true,
        isActive: true,
        isValid: true,
    },
    {
        position: 11,
        icon: PasswordIcon,
        title: "Web Login",
        onClick: () => {
            const returnDocumentId: ReturnDocumentId = {
                companyId: dropDownState
                    ? folderNode?.id?.toString()
                    : company.id,
                productId: product.productId,
                taxYearId: product.taxYear,
                folderId: folderNode.id,
                moduleId: Number(
                    getModuleId(
                        product,
                        company ? (dropDownState ? folderNode : company) : {},
                        municipalState,
                    ),
                ),
                returnId: returnNode.id,
                retalFolderId: returnNode.retalFolderId,
            };
            onWebLoginClick(returnDocumentId);
        },
        isEnabled: returnNode?.filingType === "ONLINE" || false,
        isActive: returnNode?.filingType === "ONLINE" || false,
        isValid: true,
    },
];
