import React, { FC, useEffect, useRef, useState } from "react";
import "./MunicipalStateSelector.scss";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks";
import {
    Company,
    FixMeLater,
    Jurisdiction,
    DropdownOption,
    Product,
    State,
    UserOptions,
} from "src/types";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import SimpleAutoComplete from "../SimpleAutoComplete/SimpleAutoComplete";
import { StyledCottageIcon } from "./MunicipalStateSelector.styled";
import { ProductService } from "src/services";

export type StateSelectorProps = {
    stateChangeFn: (changes: { selectedMunicipal: State | undefined }) => void;
};

const MunicipalStateSelector: FC<StateSelectorProps> = ({ stateChangeFn }) => {
    const [searchParams] = useSearchParams();
    const firstRender = useRef<boolean>(true);
    const dispatch = useAppDispatch();
    const [states, setStates] = useState<Jurisdiction[]>([]);
    const [stateOptions, setStateOptions] = useState<DropdownOption[]>([]);
    const productService = ProductService.getInstance();

    const userOptions: UserOptions | undefined = useAppSelector(
        (state) => state.UserOptions.value,
    );

    const product: Product = useAppSelector(
        (state: FixMeLater) => state?.Product?.value,
    );

    let lastStateIdFromPreferences =
            userOptions.productPreferences?.[product?.productName]?.filters
                ?.state;

    const selectedState = useAppSelector(
        (state: FixMeLater) =>
            state?.[product?.productName]?.value?.selectedState,
    );

    const selectedCompany: Company = useAppSelector(
        (state: FixMeLater) => state?.[product?.productName]?.value?.company,
    );

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const statesResponse =
                    await productService.getCompanyProductYearJurisdictions(
                        selectedCompany.id,
                        product.productId,
                        product.taxYear,
                    );
                setStates(statesResponse);

                const stateOptions = statesResponse?.map((state: State) => ({
                    value: state,
                    label: state.abbrev,
                }));

                setStateOptions(stateOptions);
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };
        
        if (selectedCompany) {
            fetchStates();
        }
    }, [selectedCompany, product.productId, product.taxYear]);

    // Check if state param exists in url
    useEffect(() => {
        let stateParam = lastStateIdFromPreferences?.toString();
        if (firstRender?.current) {
            stateParam =
                searchParams.get("selectedState") ||
                lastStateIdFromPreferences?.toString();
            firstRender.current = false;
        } 

        if (stateParam) {
            let selectedState = states?.find(
                (state: State) => 
                    state.id === parseInt(stateParam) || 
                    state.abbrev === stateParam,
            );
            if (selectedState) {
                dispatch(
                    GlobalStateActions[product?.productName]?.setSelectedState(
                        selectedState,
                    ),
                );
            }
        }
    }, [userOptions, stateOptions]);

    const handleChange = (selectedState: State) => {
        stateChangeFn({
            selectedMunicipal: selectedState,
        });
        dispatch(
            GlobalStateActions[product?.productName].setSelectedState(
                selectedState,
            ),
        );
    };

    return (
        <div className="tts-state-selector">
            <SimpleAutoComplete
                icon={<StyledCottageIcon />}
                size="small"
                label="State"
                data={stateOptions}
                onSelect={handleChange}
                selected={selectedState || ""}
                data-testid="tts-state-selector"
                disableClearButton={true}
                disabled={!selectedCompany?.id}
            />
        </div>
    );
};

export default MunicipalStateSelector;
